import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/seo";

import Fade from "react-reveal/Fade";

import MarkdownStringRenderer from "../components/MarkdownStringRenderer";

const PAGE_CONTENTS = {
  content: `### OUR COMMITMENT TO PRIVACY

Upper Canada Soap Company. and/or its related, associated, affiliated, successor and subsidiary entities, (collectively, “Upper Canada Soap”, “we”, “us” and “our”) are committed to protecting personal information of our customers, independent operators and Site visitors. This Privacy Policy (the “Policy”) governs the collection, use and disclosure of your personal information in any communication or interaction you may have with us, whether it be in-person, over the telephone, in writing, or electronic.

Upper Canada Soap complies with all of the requirements of Canada’s privacy legislation,{" "} _The Personal Information Protection and Electronic Documents Act_ {" "} and substantially similar provincial legislation. By submitting your personal information, and using Upper Canada Soap services or website (the “Site”), you accept this Policy and consent to its terms and as otherwise described to you at the time of collection of your personal information. If you do not agree to the terms of this Policy, please do not use the Site.

### POLICY AMENDMENTS

This Policy may be modified, revised or updated at Upper Canada Soap’s discretion, at any time, without notice to you. Please check back from time to time for such amendments. By continuing to view, browse or use the Site following any such modification or revision, you agree to be bound by such modification or revision.

### WHAT IS PERSONAL INFORMATION?

“Personal Information” is personally identifiable information about you, including your name, age, residential address and e-mail address, credit card and billing information, and your views and personal opinions. Publicly available information, such as business contact information or a public directory listing of your name, address, telephone number or information that is aggregated and not associated with a specific individual, including demographic information and statistics, is not considered Personal Information.

### WHAT PERSONAL INFORMATION WILL UPPER CANADA SOAP COLLECT?

We will only request personal information from you that is necessary to establish, manage and maintain our relationship with you. Personal Information is collected by Upper Canada Soap when you provide it to us, such as, contact us via the Site, or call us. We may also collect your IP address when you use the Site and we keep track of your dealings with us, including details of your visits to our Site and the resources that you access, including, but not limited to, traffic data, location data, weblogs and other communication data.

We may also receive Personal Information from our business partners and other third parties, provided that such third parties confirm to us that they have obtained your consent or we have obtained your consent in this regard.

### WHAT NON-PERSONAL INFORMATION WILL UPPER CANADA SOAP COLLECT?

We collect certain non-personal information which is recorded by the standard operation of our Internet servers on an anonymous basis, such as the operating system and type of browser you are using, the sections of the Site you visit, the Site pages visited, and any content you post to or download from the Site.

This non-identifiable information is used on an aggregate basis and in a non-personally identifiable form, including: (i) for Site and system administration purposes, (ii) to improve the Site, (iii) to conduct internal reviews of the number of visitors to the Site (iv) to help us better understand visitors’ use of our Site; (v) to respond to specific requests from our visitors; and (vi) to protect the security or integrity of our Site when necessary.

#### **_Cookies_**

When you view the Site, we collect and store some information on your computer. This information will be stored in a “Cookie,” which is a file created on your computer’s hard drive, and will help us in many ways. For example, Cookies allow us to tailor the Site to better match your interests and preferences. With most Internet browsers, you can erase Cookies from your computer hard drive, block the creation of Cookies, or receive a warning before a Cookie is stored, although doing so may affect your use of the Site and your ability to access certain features of the Site. Please refer to your browser instructions or the help section of your browser to learn more about these functions.

#### **_Other Technologies_**

Our Site may use Web beacon, gif or other technologies. When you access certain of our web pages, a non-identifiable notice of that visit is generated which may be processed by us. These technologies usually work in conjunction with cookies. If you don’t want your cookie information to be associated with your visits to these pages, you can set your browser to turn off cookies. If you turn off cookies, Web beacon technologies will still detect visits to these pages, but the notices they generate cannot be associated with other non-identifiable cookie information and are disregarded.

### HOW WILL UPPER CANADA SOAP USE THE INFORMATION WE COLLECT?

Upper Canada Soap collects and discloses your personal information for the following purposes:

*   to contact and correspond with you;
	
*   to respond to your communications and requests;
	
*   if you enter a contest or other promotion offered by Upper Canada Soap, we may, under the rules of the promotion, use your name and certain other information to administer the promotion, such as contacting, announcing and promoting the winners of the promotion;
	
*   to meet legal, regulatory, audit, security and financial requirements under federal and provincial regulations;
	
*   to maintain business records for reasonable periods of time;
	
*   to conduct market research and gather demographic and statistical information about our users, for managing our business; and
	
*   at your option, to make you aware of Upper Canada Soap projects that may be of interest to you.
	

Also, you may indicate that you are interested in receiving information from Upper Canada Soap. If so, your personal information will also be used to contact you or to send you e-mail materials and other mailings about productions or projects that might be of interest to you. You always have the option to tell us not to share your personal information with third parties or to exclude you from offers for targeted advertising.

We may be involved in the sale or transfer of some or all of our business. As part of that sale or transfer transaction, we may disclose your personal information to the acquiring organization, but will require the acquiring organization to agree to protect the privacy of your personal information in a manner that is consistent with the Policy or applicable privacy laws.

Limited disclosure of Personal Information to third parties may be required as part of Upper Canada Soap fulfilling its stated business duties and day-to-day operations. However, information will only be disclosed to such persons if they have entered into a written agreement with us to abide by this Policy.

### PROCESSING OF PERSONAL INFORMATION

Your personal information may be processed and stored outside of the province in which you reside and/or outside of Canada. Under the laws of these other jurisdictions, in certain circumstances foreign courts, law enforcement agencies or regulatory agencies may be entitled to access your personal information without notice to you. By submitting your Personal Information, you agree to this transfer, storing or processing.

### CONSENT

By providing us with your Personal Information, you consent to our using your Personal Information for the purpose set forth above. Other than as required by law, your Personal Information will not be used for any other purpose without your consent. You can at any time withdraw consent to receive further information or correspondence from us by contacting our Chief Privacy Officer at the address set forth below.

### SECURITY

Technical, contractual, administrative and physical security measures and safeguards, such as locked cabinets, restricted access and the use of passwords and firewalls have been adopted to protect your personal information against loss or theft, as well as unauthorized access, disclosure, copying, use or modification.

Information collected from our Site is kept in a safe and secure system, isolated from direct connection to the Internet.

### LINKS TO OTHER SITES

The Site may contain links to independently owned, controlled and/or managed third party, World Wide Web or Internet sites to which the Policy does not apply. Please note that we do not monitor or control the content that appears on these sites and such content may be constantly changing. If you view these sites, the sites may assign a cookie. Upper Canada Soap does not control these cookies, nor is it responsible for any marketing or other use of your information by these third parties.

### HOW TO CORRECT YOUR PERSONAL INFORMATION

Upper Canada Soap wants to ensure that information about you is accurate, complete and current. Please inform Upper Canada Soap of any change of name, address or other information. In addition, you have the right to access the Personal Information that Upper Canada Soap has collected, stored, and disclosed about you, subject to any legal restrictions. If there are errors in the information in your files, you may request that it be changed. If we have disclosed inaccurate information about you to a third party, we will be pleased to contact the third party in order to advise them of the error.

In order to access this information, you must put your request in writing to the individual referred to below.

### HOW TO ACCESS YOUR PERSONAL INFORMATION

If you have any questions, concerns or would like more information about the collection, use or disclosure of your personal information by Upper Canada Soap, or if you simply want to know whether we have any of your personal information on file, you may contact our Chief Privacy Officer at the following address:

Email: contact@uppercanadasoap.com

Mail:

E-Commerce & Digital Marketing Team

Upper Canada Soap

5875 Chedworth Way

Mississauga, Ontario, L5R 3L9

If Upper Canada Soap refuses access to you, it will provide you with the reasons for its refusal upon request. Exceptions may include information that contains references to other individuals, information that cannot be disclosed for legal, security or commercial proprietary reasons, and information that is subject to solicitor-client or litigation privilege.`,
};

class Privacy extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;

    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        HeaderExtension={
          <div className="header_extension no_bg press aligncenter">
            <div className="bg">
              <div className="container">
                <Fade bottom duration={1000} delay={125} distance={"25%"}>
                  <h2>Privacy policy</h2>
                </Fade>
              </div>
            </div>
          </div>
        }
      >
        <SEO
          title="Privacy policy"
          description="Read about our privacy policy to understand how we gather, use, disclose, and manage a customer or client’s data."
        />

        <div className="page-content pages-press">
          <div className="section-press">
            <div className="container">
              <div className="columns post-single ui-grid">
                <div className="column is-10 content">
                  <MarkdownStringRenderer
                    markdownString={PAGE_CONTENTS.content}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Privacy;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
